.main {
  background-image: url('../../images/background-homepage.png');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#myCarousel {
  min-height: 450px;
  background: url(../../images/panorama/panorama-manicure.jpg);
  background-size: cover;
}

/* .carouselImage {
  height: 100%;
  object-fit: cover;
} */

/* .showcase {
  background-size: cover;
  font-weight: bolder;
  min-height: 450px;
  color: #cfcfcf;
} */

/* .showcase .overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 450px;
  background-color: rgba(0, 0, 0, 0.45);
} */

.branding {
  color: #004aad;
  font-size: 6rem;
  font-family: Monoton, cursive;
  text-shadow: 0.3rem 0.3rem 0.3rem #888;
  padding: 0.8rem 1.6rem 1.8rem 0.8rem;
  border-radius: 0.8rem;
  opacity: 0.72
}

.service {
color: #cb6ce6;
font-size: 2.5rem;
font-family: Monoton, cursive;
/* text-shadow: 0.3rem 0.3rem 0.3rem #888; */
padding: 0.8rem 1.6rem 1.8rem 0.8rem;
border-radius: 0.8rem;
/* opacity: 0.72 */
}

.showcase .placard-inner {
padding: 2rem 2rem;
}

.showcase .card-form {
opacity: 0.9;
}

#darker-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 450px;
  background-color: rgba(0, 0, 0, 0.72);
}

#darkest-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 450px;
  background-color: rgba(0, 0, 0, 0.9);
}

.features {
  border-top: 3px solid violet;
  text-align: center;
  /* display: flex; */
  /* flex-direction: column;
  align-items: center;
  justify-content: space-evenly; */
}

.features i {
  width: 9rem;
  height: 9rem;
  font-size: 4rem;
  -webkit-text-stroke: 0.2rem #2a2727;
  color: transparent;
  border: 0.1rem solid #2a2727;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
