fieldset,
legend {
  all: revert;
}
fieldset {
  border: none;
  margin: 2rem auto;
  border-top: thin solid #b9b7b7;
}
legend {
  /* color: #888888; */
  width: auto;
  margin: 0 auto;
  padding: 0 0.4rem;
  /* background-color: #f9f9f9; */
  /* font-size: 0.8rem; */
}

.fieldset {
    border: none;
    border-top: thin solid #b9b7b7;
  }

.legend {
    /* color: #888888; */
    width: auto;
    /* margin: 0 auto; */
    /* padding: 0 0.4rem; */
    /* background-color: #f9f9f9; */
    /* font-size: 0.8rem; */
}